import React from "react";
import Link from "gatsby-link";

import logo from "../../assets/logo.svg";
import blackLogo from "../../assets/black-logo.svg";
import menu from "../../assets/menu.svg";
import hat from "../../assets/hat.png";
import close from "../../assets/x.svg";
import shoppingBag from "../../assets/shopping-bag.svg";
import shoppingBagWhite from "../../assets/shopping-bag-white.svg";
import web from "../../assets/halloween-7.svg";
import spider from "../../assets/halloween-8.svg";
import "./header.scss";
import { useMemo } from "react";

const linkStyles = {
	textDecoration: "none",
	height: "100%",
	display: "flex",
	alignItems: "center",
};

const activeStyle = {
	borderTop: "2px solid #2B4BD1",
};

const Header = ({
	cart,
	toggleMobileNavigation,
	displayNav,
	...props
	// preOrderAuthorized,
}) => {
	const domain = process.env.GATSBY_MYSHOPIFY_URL || "default";

	activeStyle.borderTop = useMemo(() => {
		if (props.halloween) {
			return "2px solid #222222";
		}
		return "2px solid #2B4BD1";
	}, [props.halloween]);

	let cartClassList = ["header__cart-icon"];
	let headerClassList = ["header"];

	if (cart?.lineItems) {
		const quantity = cart.lineItems.reduce((acc, item) => {
			return acc + item.quantity;
		}, 0);

		if (quantity > 0) {
			cartClassList.push("header__cart-icon--active");
		}
	}

	cartClassList.push("header__cart-icon--active");
	if (displayNav) {
		headerClassList.push("header--fixed");
	}

	return (
		<div className={headerClassList.join(" ")}>
			<div className="header__halloween-container">
				<img
					src={web}
					className="halloween-only header__halloween-web"
				/>
				<img
					src={spider}
					className="halloween-only header__halloween-spider spider"
				/>
			</div>
			<div
				className="header__nav"
				onClick={() => toggleMobileNavigation()}
			>
				<img
					src={displayNav ? close : menu}
					className="header__hamburger"
					alt="Menu"
				/>
			</div>
			<div className="header__left">
				<Link
					to="/"
					aria-label="Home"
					className="header__logo--container"
				>
					<img
						src={logo}
						className="header__logo not-halloween"
						alt="Tearribles Logo"
					/>
					<img
						src={blackLogo}
						className="header__logo halloween-only"
						alt="Tearribles Logo"
					/>
					{/* {!props.preOrderAuthorized && (
						<img
							src={hat}
							className="header__logo--ears"
							alt="Tearribles Logo"
						/>
					)} */}
				</Link>
				<div className="header__spacer" />
				<div className="header__spacer" />
				<Link
					to="/products"
					style={linkStyles}
					activeStyle={activeStyle}
					aria-label="Dog Toys"
				>
					<div className="header__link">Dog Toys</div>
				</Link>
				{domain !== "tearriblesau.myshopify.com" ? (
					<>
						<div className="header__spacer" />
						<Link
							to="/products/combos"
							style={linkStyles}
							activeStyle={activeStyle}
							aria-label="Combos"
						>
							<div className="header__link">Combos</div>
						</Link>
					</>
				) : null}
				{domain !== "tearriblesau.myshopify.com" ? (
					<>
						<div className="header__spacer" />
						<Link
							to="/collections/baloomba-yak-chews"
							style={linkStyles}
							activeStyle={activeStyle}
							aria-label="Chews"
						>
							<div className="header__link">Treats & Chews</div>
						</Link>
					</>
				) : null}
				{domain !== "tearriblesau.myshopify.com" ? (
					<>
						<div className="header__spacer" />
						<Link
							to="/products/gear"
							style={linkStyles}
							activeStyle={activeStyle}
							aria-label="Gear"
						>
							<div className="header__link">Gear</div>
						</Link>
					</>
				) : null}
				<div className="header__spacer" />
				<Link
					to="/product/tearribles-digital-gift-card"
					style={linkStyles}
					activeStyle={activeStyle}
					aria-label="Gift Cards"
				>
					<div className="header__link">Gift Cards</div>
				</Link>
			</div>
			<div className="header__right">
				<Link
					to="/faq"
					style={linkStyles}
					activeStyle={activeStyle}
					aria-label="FAQ"
				>
					<div className="header__link">FAQ</div>
				</Link>
				<div className="header__spacer" />
				<Link
					to="/story"
					style={linkStyles}
					activeStyle={activeStyle}
					aria-label="Our Story"
				>
					<div className="header__link">Our Story</div>
				</Link>
				<div className="header__spacer" />
				<Link
					to="/blog/prey-drive"
					style={linkStyles}
					activeStyle={activeStyle}
					aria-label="Blog"
				>
					<div className="header__link">Blog</div>
				</Link>
				<div className="header__spacer" />
				<Link
					to="/cart"
					style={linkStyles}
					activeStyle={activeStyle}
					aria-label="Cart"
				>
					<div className="header__cart header__link">
						{cart?.lineItems && cart.lineItems.length > 0 ? (
							<div className="header__cart-wrapper header__cart-wrapper--active">
								<img
									src={shoppingBagWhite}
									className={cartClassList.join(" ")}
									alt="Cart"
								/>
							</div>
						) : (
							<img
								src={shoppingBag}
								className={cartClassList.join(" ")}
								alt="Cart"
							/>
						)}
					</div>
				</Link>
				<div className="header__spacer" />
			</div>
		</div>
	);
};

export default Header;
